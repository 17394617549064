import LooliaCloset from "../../assets/loolia.png";
import SohatiCare from "../../assets/sohati.png";
import Verb from "../../assets/verb.png";
import SexxxPlus from "../../assets/sexxxplus.png";
import Naturesage from "../../assets/naturesage.png";
import Bimoo from "../../assets/Bimoo.png";
import PartyOMax from "../../assets/PartyOMax.png";
import Vignoble from "../../assets/vignoble.png";
import DV from "../../assets/DistributionVision.png";
import KoiLeb from "../../assets/Koileb.png";
import CG from "../../assets/MoteurGranby.png";
import CC from "../../assets/CapsuleCase.png";
import VMania from "../../assets/VMania.png";
import ChapterOne from "../../assets/ChapterOne.png";
import ElectronsTech from "../../assets/ElectronsTech.png";
import MMS from "../../assets/MMS.png";
import Go from "../../assets/Go.png";
import Verger from "../../assets/flancnord.png";
import LF from "../../assets/LF.png";
import Authentic from "../../assets/AuthenticS.png";
import PlanB from "../../assets/planb.png";
import BulletVPN from "../../assets/BulletVPN.png";
import Aura from "../../assets/Aura.png";
import ChaosArtGallery from "../../assets/ChaosArtGallery.png";
import CO from "../../assets/CliniqueOdessey.png";
import Khatib from "../../assets/El-KhatibLawFirm.png";
import GA from "../../assets/GayaneAver.png";
import JeuxMania from "../../assets/JeuxMania.png";
import WastedTreasures from "../../assets/WastedTreasures.png";
import RIIEQ from "../../assets/RIIEQ.png";
import SNT from "../../assets/SNT.png";
import SIS from "../../assets/SIS.png";
import LR from "../../assets/Learnopolis.png";
import WillGo from "../../assets/WillGo.png";
import JuniorKids from "../../assets/JuniorKids.png";
import Twysted from "../../assets/Twysted.png";
import GMR from "../../assets/GMR.png";
import Filco from "../../assets/Filco.png";
import CF from "../../assets/Caesars Flame.png";
import CL from "../../assets/CuriousLemons.png";

export const projectsData = [
  {
    id: 1,
    image: SexxxPlus,
    title: "Boutique SP",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://sexxxplus.com/",
  },
  {
    id: 2,
    image: MMS,
    title: "Mercer Mackay",
    category: "WordPress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://mercermackay.com/",
  },
  {
    id: 3,
    image: Verb,
    title: "Verb Products",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.verbproducts.com",
  },
  {
    id: 4,
    image: KoiLeb,
    title: "KoiBei",
    category: "shopify",
    company: "Freelance",
    location: "Lebanon",
    link: "https://koibei.com/",
  },
  {
    id: 5,
    image: Verger,
    title: "Verger Du Flanc Nord",
    category: "WordPress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://vergerduflancnord.com",
  },
  {
    id: 6,
    image: CC,
    title: "Capsul Case",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.capsulcase.com/",
  },
  {
    id: 7,
    image: Go,
    title: "Go360",
    category: "WordPress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://go360canada.com/",
  },
  {
    id: 8,
    image: Vignoble,
    title: "Vignoble Des Coteaux Artisans",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.coteaudesartisans.com/",
  },
  {
    id: 9,
    image: PlanB,
    title: "Plan B Concept",
    category: "WordPress",
    company: "Freelance",
    location: "Lebanon",
    link: "http://pbc-lb.com/",
  },
  {
    id: 10,
    image: ChapterOne,
    title: "Chapter One",
    category: "shopify",
    company: "Freelance",
    location: "Lebanon",
    link: "https://chapteronee.com/",
  },
  {
    id: 11,
    image: LF,
    title: "Les FinFinettes",
    category: "WordPress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://lesfinfinettes.com/",
  },
  {
    id: 12,
    image: PartyOMax,
    title: "Party Ô Max",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://partyomax.com/",
  },
  {
    id: 13,
    image: CG,
    title: "Centre Du Moteur Granby",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.centredumoteurgranby.ca/",
  },
  {
    id: 14,
    image: Authentic,
    title: "Authentik Securite",
    category: "WordPress",
    company: "Freelance",
    location: "Montreal, Qc",
    link: "https://authentiksecurite.com/",
  },
  {
    id: 15,
    image: ElectronsTech,
    title: "Electrons Tech",
    category: "shopify",
    company: "Freelance",
    location: "Lebanon",
    link: "https://electronstech.com/",
  },
  {
    id: 16,
    image: BulletVPN,
    title: "BulletVPN",
    category: "WordPress",
    company: "Securealm",
    location: "Lebanon",
    link: "https://www.bulletvpn.com/",
  },
  {
    id: 17,
    image: SohatiCare,
    title: "SohatiCare",
    category: "shopify",
    company: "Parallel Holdings",
    location: "Egypt",
    link: "https://www.eg.sohaticare.com/",
  },
  {
    id: 18,
    image: VMania,
    title: "V-Mania",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.vapeshopmania.ca/",
  },
  {
    id: 19,
    image: Naturesage,
    title: "Naturesage",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://naturesageomega3.com/",
  },
  {
    id: 20,
    image: Bimoo,
    title: "BimOo",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://bimoo.ca/",
  },
  {
    id: 21,
    image: DV,
    title: "Distribution Vision",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://boutique-distribution-vision.com/",
  },
  {
    id: 22,
    image: LooliaCloset,
    title: "LooliaCloset",
    category: "shopify",
    company: "Parallel Holdings",
    location: "Egypt",
    link: "https://eg.looliacloset.com/",
  },
  {
    id: 23,
    image: Aura,
    title: "Aura Agency",
    category: "Wordpress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.auraagency.com/",
  },
  {
    id: 24,
    image: ChaosArtGallery,
    title: "Chaos Art Gallery",
    category: "Wordpress",
    company: "Freelance",
    location: "Lebanon, Beirut",
    link: "https://www.chaosartgallery.com/",
  },
  {
    id: 25,
    image: CO,
    title: "Clinique Odyssey",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://cliniqueodyssey.com/",
  },
  {
    id: 26,
    image: Khatib,
    title: "El-Khatib Law Firm",
    category: "Wordpress",
    company: "Freelance",
    location: "Lebanon, Beirut",
    link: "https://www.khatiblegal.com/",
  },
  {
    id: 27,
    image: GA,
    title: "Gayane Avertisyan",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.gayaneavetisyan.ca/",
  },
  {
    id: 28,
    image: JeuxMania,
    title: "Jeux Mania",
    category: "shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.jeux-mania.ca/",
  },
  {
    id: 29,
    image: WastedTreasures,
    title: "Wasted Treasures",
    category: "Wordpress",
    company: "Freelance",
    location: "Lebanon, Beirut",
    link: "https://www.wastedtreasures.org/",
  },
  {
    id: 30,
    image: RIIEQ,
    title: "RIIEQ",
    category: "Wordpress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.riieq.ca/",
  },
  {
    id: 31,
    image: SNT,
    title: "Le Studio N.20",
    category: "Wordpress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.lestudiono20.com/",
  },
  {
    id: 32,
    image: SIS,
    title: "SIS Lebanon",
    category: "Wordpress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://www.sis-lebanon.com/",
  },
  {
    id: 33,
    image: LR,
    title: "Learnopolis",
    category: "Wordpress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://learnopolis-institute.com/",
  },
  {
    id: 34,
    image: WillGo,
    title: "Déménagement Will Go",
    category: "Wordpress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://demenagementwillgo.com/",
  },
  {
    id: 35,
    image: JuniorKids,
    title: "Junior Kids",
    category: "Shopify",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://juniorkids.ca/",
  },
  {
    id: 36,
    image: GMR,
    title: "Green Mount Recycling",
    category: "WordPress",
    company: "Freelance",
    location: "Lebanon, Beirut",
    link: "https://www.greenmountrecycling.com/",
  },
  {
    id: 37,
    image: Filco,
    title: "Filco LB",
    category: "WordPress",
    company: "Freelance",
    location: "Lebanon, Beirut",
    link: "https://www.filco-lb.com/",
  },
  {
    id: 38,
    image: Twysted,
    title: "Twysted",
    category: "WordPress",
    company: "Freelance",
    location: "Lebanon, Beirut",
    link: "#",
  },
  {
    id: 39,
    image: CL,
    title: "Curious Lemon",
    category: "WordPress",
    company: "Growth Hacker",
    location: "Montreal, Qc",
    link: "https://curiouslemons.com/",
  },
  {
    id: 40,
    image: CF,
    title: "Caesars Flame",
    category: "WordPress",
    company: "Freelance",
    location: "Lebanon, Beirut",
    link: "https://caesarsflame.com/",
  },
];

export const projectNav = [
  {
    name: "all",
  },
  {
    name: "shopify",
  },
  {
    name: "wordPress",
  },
];
