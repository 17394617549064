import React from "react";

export const Data = [
  {
    id: 1,
    title: "Ibrahim Zaraket",
    description:
      "The most trustworthy developer I’ve worked with. I am considering Abed for all my upcoming work when it comes to Shopify and WordPress. Delivers high quality with high results, and never failed or disappoint me!",
  },
  {
    id: 2,
    title: "Moussa Arbid",
    description:
      "Abed is a very passionate person. I was his technical lead and he always showed me consistency in his work, attention to details, and most important thing, his honesty in his work. I couldn't be more proud of him for years",
  },
  {
    id: 3,
    title: "Nourhane Shbaro",
    description:
      "Exceptional experience working with Abed! His speed, attention to detail, and unwavering helpfulness made our collaboration smooth and efficient. His expertise contributed to the success of my project. Highly recommend for anyone seeking a skilled and reliable web developer!",
  },
  {
    id: 4,
    title: "Jad Kawas",
    description:
      "Abed and I worked together on my law online library, so i can have all my archive cases in 1 place. the database is well structured, the UI of the app is very user-friendly, and the communication with Abed was amazing throughout all the process",
  },
  {
    id: 5,
    title: "Ibrahim Hamdan",
    description:
      "As a coach, I needed some templates and consultations for online scheduling and training planning. I got introduced to Abed, and step by step we achieved what I needed. The project was on point and I believe all the trainings and explanations given by Abed is worth every penny.",
  },
];
